<template>
  <div class="h-screen flex w-full bg-img">
    <div
      class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/1 mx-auto text-center">
      <vs-card class="card" style="min-height: 90%;">
        <vs-navbar v-model="activeItem" class="p-2">
          <div slot="title">
            <vs-navbar-title>
              <span>Documentación {{dataInterface.name}}</span>
            </vs-navbar-title>
          </div>

          <vs-navbar-item index="0">
            <a href="#" @click="filterType('get')">GET</a>
          </vs-navbar-item>

          <vs-navbar-item index="1">
            <a href="#" @click="filterType('post')">POST</a>
          </vs-navbar-item>

          <vs-navbar-item index="2">
            <a href="#" @click="filterType('put')">PUT</a>
          </vs-navbar-item>

          <vs-navbar-item index="3">
            <a href="#" @click="filterType('delete')">DELETE</a>
          </vs-navbar-item>

          <vs-input icon-pack="feather" icon="icon-search" placeholder="Buscar" v-model="search"/>
        </vs-navbar>
        <vs-divider class="mb-0"></vs-divider>

        <div class="mt-8 mb-0" :style="'height:'+(h-200)+'px; overflow:auto'">
          <table width="100%" cellspacing="10" style="margin-top: -10px; float: left">
            <tr v-for="(fun, key) in functionsFiltered" :key="key">
              <td align="left" width="5%" style="border: 2px solid rgba(0,0,0,0.2); padding: 10px">
                <h3>{{fun.name}}</h3><br>
                <h4><span :class="'method-'+ getMethod(fun.method).type">{{getMethod(fun.method).type}}</span>
                  https://api-rest.moshbits.com{{getMethod(fun.method).url}}
                </h4>
                <br>
                <p style="font-size: 14px; margin-top: -6px">{{fun.description}}</p><br>
                <pre style="font-size: 14px">{{getParams(fun._id)}}</pre>
              </td>
            </tr>
          </table>
        </div>
      </vs-card>
    </div>
  </div>
</template>

<script>

  import ServicesAPI from "@/services/api-services";

  export default {
    data() {
      return {
        activeItem: 0,
        search: "",
        interface: this.$route.params.interface,
        url: location.href,
        dataInterface: {},
        functionsFiltered: [],
        h: window.innerHeight
      }
    },
    methods: {
      getInterface() {

        ServicesAPI.methods.apiGET({
          url: 'api/interface/getInterface',
          params: {
            interface: this.interface
          }
        }, res => {

          if (res.code != 200)
            this.$vs.notify({
              title: res.code,
              text: res.message,
              color: 'danger',
              iconPack: 'feather',
              icon: 'icon-alert-circle'
            });

          this.dataInterface = res.data[0];
          this.functionsFiltered = this.dataInterface.functions;
        });
      },
      getMethod(method) {
        return this.dataInterface.methods.filter(fil => fil.method == method)[0]
      },
      filterType(type) {

        var filtered = [];

        for (const fil of this.dataInterface.functions) {
          if (this.getMethod(fil.method).type == type)
            filtered.push(fil);
        }

        this.functionsFiltered = filtered;
      },
      getParams(id) {

        let func = this.dataInterface.functions.filter(fil => fil._id == id)[0], paramsPOST = {}, params = {};

        for (const par of func.sql) {

          let prepareParams = {};

          for (const par of par.params) {
            prepareParams[par] = 'VALOR';
          }
          paramsPOST[par.name] = prepareParams;

          if (par.search.length > 0)
            params['SEARCH'] = 'VALOR';
        }

        for (const par of func.params) {
          params[par] = 'VALOR';
        }

        return {
          function: func.id,
          session: 'TOKEN_DE_SESSION',
          params: this.getMethod(func.method).type == 'post' || this.getMethod(func.method).type == 'put' ? paramsPOST : params
        }
      }
    },
    mounted() {
      this.getInterface();
    },
    watch: {
      search(val, oldVal) {

        var filtered = [];

        for (const fil of this.dataInterface.functions) {
          if (fil.description.toLowerCase().search(val.toLowerCase()) >= 0 || fil.name.toLowerCase().search(val.toLowerCase()) >= 0)
            filtered.push(fil);
        }

        this.functionsFiltered = filtered;
      }
    }
  }
</script>

<style>
  .method-get {
    border: 1px solid #2D45A9;
    font-weight: bold;
    background: #2D45A920;
    padding: 0px 3px;
    border-radius: 3px;
    color: #2D45A9;
    text-transform: uppercase;
  }

  .method-post {
    border: 1px solid #20CB6D;
    font-weight: bold;
    background: #20CB6D20;
    padding: 0px 3px;
    border-radius: 3px;
    color: #20CB6D;
    text-transform: uppercase;
  }

  .method-put {
    border: 1px solid #FF9D3E;
    font-weight: bold;
    background: #FF9D3E20;
    padding: 0px 3px;
    border-radius: 3px;
    color: #FF9D3E;
    text-transform: uppercase;
  }

  .method-delete {
    border: 1px solid #DE595C;
    font-weight: bold;
    background: #DE595C20;
    padding: 0px 3px;
    border-radius: 3px;
    color: #DE595C;
    text-transform: uppercase;
  }
</style>
